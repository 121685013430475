












































































































































import PageLayout from "@/components/layouts/PageLayout.vue";
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {
    PageLayout,
  }
})
export default class About extends Vue {

}
